import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton-categories-carousel',
  standalone: true,
  imports: [],
  templateUrl: './skeleton-categories-carousel.component.html',
  styleUrl: './skeleton-categories-carousel.component.sass'
})
export class SkeletonCategoriesCarouselComponent {
    @Input() public title: boolean = true;
    @Input() public pagination: boolean = true;
}
